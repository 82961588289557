// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ocKo2ApCnz3psYX26TSG{grid-row:2;height:34px;text-align:center;font-size:18px;font-weight:bold;margin:0 10px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/clampDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".clampBlock {\r\n    grid-row: 2;\r\n    height: 34px;\r\n    text-align: center;\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin: 0 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clampBlock": `ocKo2ApCnz3psYX26TSG`
};
export default ___CSS_LOADER_EXPORT___;
