// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YTT4jioPWMcJqCajgHk7{grid-row:2;min-height:34px;text-align:center;font-size:18px;font-weight:bold;margin:0 10px 5px;display:grid;align-content:center}.YTT4jioPWMcJqCajgHk7.GMllR9w310ummP7bd9SF{font-size:17px}.WfZFIN2edinD_fKl0XpF{border-color:#464348;background:#464348}.dn6UUx3IdToaNPPC4fQ5{border-color:#66491b;background:#66491b}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/inputDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,oBAAA,CAEA,2CACI,cAAA,CAIR,sBACI,oBAAA,CACA,kBAAA,CAGJ,sBACI,oBAAA,CACA,kBAAA","sourcesContent":[".input-block {\r\n    grid-row: 2;\r\n    min-height: 34px;\r\n    text-align: center;\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin: 0 10px 5px;\r\n    display: grid;\r\n    align-content: center;\r\n\r\n    &.small-font {\r\n        font-size: 17px;\r\n    }\r\n}\r\n\r\n.constant {\r\n    border-color: #464348;\r\n    background: #464348;\r\n}\r\n\r\n.inspector {\r\n    border-color: #66491b;\r\n    background: #66491b;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-block": `YTT4jioPWMcJqCajgHk7`,
	"small-font": `GMllR9w310ummP7bd9SF`,
	"constant": `WfZFIN2edinD_fKl0XpF`,
	"inspector": `dn6UUx3IdToaNPPC4fQ5`
};
export default ___CSS_LOADER_EXPORT___;
