// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gNn89IY11K82UKHTc2tC{display:none !important}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/blockNodeData.modules.scss"],"names":[],"mappings":"AAAA,sBACI,uBAAA","sourcesContent":[".hidden {\r\n    display: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `gNn89IY11K82UKHTc2tC`
};
export default ___CSS_LOADER_EXPORT___;
